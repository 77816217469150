<template>
  <teleport to=".header">
    <div v-if="modelValue" class="modal-overlay" @click="closeModal">
      <div class="modal-content">
        <div class="search-modal" @click.stop><slot :close-modal="closeModal" /></div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts" setup>
const { modelValue } = defineProps<{
  modelValue: Boolean;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
onUnmounted(() => enableScrolling());
onMounted(() => disableScrolling());

function disableScrolling() {
  document.body.classList.add('no-scroll-modal');
}

function enableScrolling() {
  document.body.classList.remove('no-scroll-modal');
}

function closeModal(): void {
  emit('update:modelValue', false);
}
</script>
  
  <style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  z-index: 2000;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: start;

  background-color: rgb(20 19 4 / 50%);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 100vh;

  border-radius: 8px;
}

.search-modal {
  width: fit-content;
  margin-top: 20px;
}
</style>
  